import create from 'zustand';
import GameApi from '../api/GameApi';

const defaultGame = {
    _id: 1,
    id: '',
    name: '',
    gametype: '',
    category: '',
    aggregator: '',
    provider: '',
    order: 0,
    image: '',
    isonline: true,
    status: true,
    opens: 0,
    detail: {
        stargameId: 1350,
        gameTypeUnified: ''
    }
};

const useGameStore = create((set: any) => ({
    providerList: [],
    game: defaultGame,
    provider: {
        value: '',
        label: ''
    },
    currentProviders: [],

    getProviders: async (reqData) => {
        const rdata = await GameApi.getProviders(reqData);
        if (rdata) {
            return rdata.data.data;
        } else {
            return [];
        }
    },
    getProviderHistory: async (reqData) => {
        const rdata = await GameApi.getProviderHistory(reqData);
        if (rdata) {
            return rdata.data.data;
        } else {
            return [];
        }
    },
    getGames: async (reqData: object) => {
        const rdata = await GameApi.getGames(reqData);
        if (rdata) {
            return rdata.data.data;
        } else {
            return [];
        }
    },
    getGameUrl: async (data) => {
        const rdata = await GameApi.getGameUrl(data);
        if (rdata) {
            return rdata.data;
        } else {
            return false;
        }
    },
    getGameTypeList: async () => {
        const rdata = await GameApi.getGameTypeList();
        if (rdata) {
            return rdata.data.data;
        } else {
            return [];
        }
    },
    getHomePageGameData: async (data = {}) => {
        const rdata = await GameApi.getHomePageGameData(data);
        if (rdata) {
            set({
                providerList: rdata.data.data.providers
            });
            return rdata.data.data;
        } else {
            return [];
        }
    },
    // ///////////////

    selectGame: (game, provider) => {
        set({ game, provider });
    },
    setSearchValue: (value) => {
        set({ searchValue: value });
    }
}));

export default useGameStore;
