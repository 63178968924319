import { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import AuthZustand from '../../zustand/AuthZustand';
// import ConifgZustand from '../../zustand/ConifgZustand';

const AuthProvider = ({ children }) => {
    // const { i18n } = useTranslation();
    const { sessionCheck } = AuthZustand((state) => state);
    // const { getMultilang } = ConifgZustand((state) => state);
    const [flag, setFlag] = useState(false);

    const init = async () => {
        await sessionCheck();
        // const langData = await getMultilang();
        // for (let i = 0; i < langData.langs.length; i++) {
        //     i18n.addResourceBundle(
        //         langData.langs[i].language,
        //         'translation',
        //         langData.data[langData.langs[i].language]
        //     );
        // }
        setFlag(true);
    };
    useEffect(() => {
        init();
        // eslint-disable-next-line
    }, []);

    return <>{flag ? children : ''}</>;
};

export default AuthProvider;
