import { GameItemData } from '../pages/game/types';
import { Root } from '../config/config';

export const generateTransparentBackground = (theme, colorKey, opacity) => {
    const keys = colorKey.split('.');
    let color = theme;

    for (const key of keys) {
        color = color[key];
        if (!color) {
            return 'transparent';
        }
    }

    const match = color.match(/\d+/g);
    if (match) {
        const [r, g, b] = match;
        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    }

    return 'transparent';
};

export const generateBackgroundImageByThemeMode = (mode) => {
    switch (mode) {
        case 'blue':
            return '/image/site/gamebg/blue.png';
        case 'white':
            return '/image/site/gamebg/white.png';
        case 'purple':
            return '/image/site/gamebg/purple.png';
        case 'black':
            return '/image/site/gamebg/black.png';
        case 'green':
            return '/image/site/gamebg/green.png';
        case 'dark':
            return '/image/site/gamebg/background.gif';
        default:
            return '/image/site/gamebg/background.gif';
    }
};

export const checkIsURL = (url: string): boolean => {
    try {
        new URL(url);
        return true;
    } catch (e) {
        return false;
    }
};

export const getGameImageSrc = (game: GameItemData) => {
    const imagePath = `${Root.serverUrl}/image/game/`;
    const isURL = checkIsURL(game.image);
    if (isURL) {
        return game.image;
    }
    return imagePath.concat(game.image);
};

export const testWebpSupport = () => {
    const webpImage = new Image();
    webpImage.src =
        'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
    webpImage.onload = webpImage.onerror = function () {
        localStorage.setItem('webp-support', String(webpImage.height === 2));
    };
};

export const getFeaturedGameImageSrc = (game: GameItemData) => {
    return game.image
};

export const getSliderImageSrc = (slide) => {
    const imagePath = Root.serverUrl;
    const webpSupport = localStorage.getItem('webp-support') === 'true';

    if (slide.optimizedImage && webpSupport) {
        return imagePath.concat(slide.optimizedImage);
    }

    return imagePath.concat(slide.image);
};

export const getPromoImageSrc = (
    bonus: any,
    device: 'mobile' | 'desktop',
    category: 'banner' | 'home' | 'preview',
    language: string
) => {
    if (bonus[category] && bonus[category][device]) {
        const webpSupport = localStorage.getItem('webp-support') === 'true';
        const imagePath = Root.adminWebsiteUrl;
        const optimizedImage = bonus[category][device][`${language}_optimized`];
        const originalImage = bonus[category][device][language];
        const enImage = bonus[category][device]['en'];

        if (optimizedImage && webpSupport) {
            return imagePath.concat(optimizedImage);
        }

        if (originalImage) {
            return imagePath.concat(originalImage);
        }

        if (enImage) {
            return imagePath.concat(enImage);
        }
    }
    return '';
};

export const switchChain = async (
    provider: any,
    chainId: number,
    chainData: any
) => {
    try {
        await provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId }]
        });
    } catch (error) {
        if (error.code === 4902) {
            try {
                await provider.request({
                    method: 'wallet_addEthereumChain',
                    params: [chainData]
                });
            } catch (error) {
                alert(error.message);
            }
        }
    }
};

export const changeAddressFormat = (original) => {
    return `${original.substr(0, 7)}...${original.substr(-5)}`;
};
