import APIAbstract from './APIAbstract';
import { Root } from '../config/config';

class GameApi extends APIAbstract {
    getProviders(data) {
        return this.post('/player/game/getProviders', data);
    }
    getProviderHistory(data) {
        return this.post('/player/game/getProviderHistory', data);
    }
    getGames(data) {
        return this.post('/player/game/getGames', data);
    }
    getGameUrl(data = {}) {
        return this.post('/player/game/getGameUrl', data);
    }
    getGameTypeList() {
        return this.post('/player/game/getGameTypeList');
    }
    getHomePageGameData(data) {
        return this.post('/player/game/getHomePageGameData', data);
    }
}

const GameClass = new GameApi(`${Root.serverUrl}`);

export default GameClass;
