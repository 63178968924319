import PhoneInput from 'react-phone-input-2';
import styled from 'styled-components';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Container from '@mui/material/Container';
import TableContainer from '@mui/material/TableContainer';

import TabPanel from './base/tab';

export const ProfileContent = styled(Container)`
    padding: 1rem;
    background: transparent;

    th,
    td {
        padding: 1rem 0.5rem;
        color: ${({ theme }) => theme.text};
    }

    svg {
        color: ${({ theme }) => theme.text};
    }

    @media (max-width: 1024px) {
        padding: 1rem 0;
        width: calc(100% - 2rem);
    }
`;
export const ProfileFooter = styled(Box)({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    padding: '1rem 0 0 0.5rem',
    '@media (max-width: 1024px)': {
        paddingBottom: '2rem'
    }
});
export const IntroContentTop = styled(Box)`
    margin-top: 1rem;
    box-shadow: 0px -2px 4px -1px rgba(0, 0, 0, 0.2),
        0px -4px 5px 0px rgba(0, 0, 0, 0.14),
        0px -1px 10px 0px rgba(0, 0, 0, 0.12);
    padding: 2rem;
    background: ${(props) => props.theme.background.secondary};

    @media (max-width: 1024px) {
        padding: 1rem;
        width: calc(100% - 2rem);
    }

    & .MuiTablePagination-root {
        color: ${({ theme }) => theme.text} !important;
    }

    & p,
    & div,
    & li {
        font-family: 'PT Sans' !important;
    }
`;
export const IntroContent = styled(Box)`
    background: ${(props) => props.theme.background.secondary};
    box-shadow: ${(props) =>
        props.theme.mode === 'light' ? 'unset' : '0 1px 3px rgb(0 0 0 / 40%)'};
    padding: 2rem;
    @media (max-width: 1024px) {
        background: unset;
        box-shadow: unset;
        padding: 0;
    }

    & input:-webkit-autofill,
    & input:-webkit-autofill:hover,
    & input:-webkit-autofill:focus,
    & input:-internal-autofill-selected {
        -webkit-box-shadow: 0 0 0 100px
            ${(props) => props.theme.background.secondary} inset !important;
        -webkit-text-fill-color: ${(props) => props.theme.text} !important;
        caret-color: ${(props) => props.theme.background.secondary} !important;
        background-color: ${(props) =>
            props.theme.background.secondary} !important;
    }

    & .MuiFormControl-root {
        background-color: ${(props) =>
            props.theme.background.secondary} !important;
    }

    & input:-webkit-autofill,
    & input:-webkit-autofill:hover,
    & input:-webkit-autofill:focus {
        -webkit-box-shadow: none !important;
        -webkit-text-fill-color: ${(props) => props.theme.text} !important;
        caret-color: ${(props) => props.theme.background.secondary} !important;
    }

    & .MuiPaper-root {
        background: ${(props) => props.theme.background.secondary};
    }
    & .MuiAutocomplete-popper .MuiAutocomplete-listbox {
        color: ${(props) => props.theme.text} !important;
        //padding: 0;
        background: ${(props) => props.theme.background.secondary} !important;
    }

    & .MuiAutocomplete-option {
        color: ${(props) => props.theme.text};
        background: ${(props) => props.theme.background.secondary} !important;
    }

    label,
    div {
        font-family: PT Sans !important;
    }
`;
export const AuthContent = styled(Box)`
    box-shadow: 0 1px 3px rgb(0 0 0 / 40%);
    padding: 1rem;
    margin-top: 0.5rem;
    padding-top: 15px;
    padding-bottom: 15px;
    background: ${({ theme }) => theme.background.secondary};
    width: calc(100% - 2rem);
`;
export const ProfileHeader = styled(Box)`
    .MuiTabs-scroller {
        border-bottom: 1px solid #949494 !important;
    }
    .MuiTabs-indicator {
        background-color: ${({ theme }) => theme.primaryButton} !important;
        height: 3px;
    }
    button {
        color: ${(props) => props.theme.text} !important;
        font-family: League Spartan, Cyrillic !important;
    }
`;
export const LetterInput = styled(TextField)`
    width: 100%;
    margin-top: 0.5rem !important;

    & input {
        color: ${({ theme }) => theme.borderColor} !important;
        font-family: 'PT Sans' !important;
        -webkit-text-fill-color: ${({ theme }) => theme.borderColor} !important;
        -webkit-box-shadow: none !important;
    }

    & input:-webkit-autofill,
    & input:-webkit-autofill:hover,
    & input:-webkit-autofill:focus,
    & input:-internal-autofill-selected {
        -webkit-box-shadow: 0 0 0 100px
            ${(props) => props.theme.background.secondary} inset !important;
        -webkit-text-fill-color: ${(props) => props.theme.text} !important;
        background-color: ${(props) =>
            props.theme.background.secondary} !important;
    }

    & .MuiFormControl-root {
        background-color: ${(props) =>
            props.theme.background.primary} !important;
    }

    & input:-webkit-autofill {
        background: ${({ theme }) => theme.background.secondary};
    }

    & label {
        color: ${({ theme }) => theme.borderColor} !important;
        font-family: 'PT Sans' !important;
    }

    & label.Mui-focused {
        color: ${({ theme }) => theme.borderColor};
    }

    & label.Mui-disabled {
        color: ${({ theme }) => theme.borderColor} !important;
    }

    & .MuiOutlinedInput-root {
        & fieldset {
            border-color: ${({ theme }) => theme.borderColor} !important;
        }
        &:hover fieldset {
            border-color: ${({ theme }) => theme.borderColor} !important;
        }

        &.Mui-focused fieldset {
            border-color: ${({ theme }) => theme.borderColor} !important;
        }

        &.Mui-disabled fieldset {
            border-color: ${({ theme }) => theme.borderColor} !important;
        }
    }
`;
export const PhoneInputField = styled(PhoneInput)`
    width: 100%;
    position: relative;
    margin-top: 0.5rem !important;
    & input {
        width: 100% !important;
        height: 56px !important;
        color: ${({ theme }) => theme.borderColor} !important;
        font-family: 'PT Sans' !important;
        border-color: ${({ theme }) => theme.borderColor} !important;
        -webkit-text-fill-color: ${({ theme }) => theme.borderColor} !important;
        -webkit-box-shadow: none !important;
        background: ${(props) => props.theme.background.secondary} !important;
    }
    & input::placeholder {
        opacity: 0.5 !important;
    }

    & .open {
        background: transparent !important;
    }

    & .flag-dropdown {
        border-color: ${({ theme }) => theme.borderColor} !important;
        background: transparent !important;
    }

    & .flag-dropdown:hover {
        background: transparent !important;
    }
    & .flag-dropdown .selected-flag {
        background: transparent !important;
    }

    & .flag-dropdown .selected-flag:hover {
        background: transparent !important;
    }
    & .flag-dropdown .country-list {
        background: rgb(32, 35, 48) !important;
    }

    & .flag-dropdown .country-list .country .country-name {
        color: white !important;
    }

    & .flag-dropdown .country-list .country.highlight {
        background: rgb(32, 50, 78) !important;
    }

    & .flag-dropdown .country-list .country:hover {
        background: transparent !important;
    }

    & input:-webkit-autofill,
    & input:-webkit-autofill:hover,
    & input:-webkit-autofill:focus,
    & input:-internal-autofill-selected {
        -webkit-box-shadow: 0 0 0 100px
            ${(props) => props.theme.background.secondary} inset !important;
        -webkit-text-fill-color: ${(props) => props.theme.text} !important;
        caret-color: ${(props) => props.theme.background.secondary} !important;
        background-color: ${(props) =>
            props.theme.background.secondary} !important;
    }

    & .MuiFormControl-root {
        background-color: ${(props) =>
            props.theme.background.primary} !important;
    }

    & input:-webkit-autofill {
        background: ${({ theme }) => theme.background.secondary};
    }

    & label {
        color: ${({ theme }) => theme.borderColor} !important;
        font-family: 'PT Sans' !important;
    }

    & label.Mui-focused {
        color: ${({ theme }) => theme.borderColor};
    }

    & label.Mui-disabled {
        color: ${({ theme }) => theme.borderColor} !important;
    }

    & .MuiOutlinedInput-root {
        & fieldset {
            border-color: ${({ theme }) => theme.borderColor} !important;
        }
        &:hover fieldset {
            border-color: ${({ theme }) => theme.borderColor} !important;
        }

        &.Mui-focused fieldset {
            border-color: ${({ theme }) => theme.borderColor} !important;
        }

        &.Mui-disabled fieldset {
            border-color: ${({ theme }) => theme.borderColor} !important;
        }
    }
`;
export const AccessButton = styled(Button)`
    min-width: 100%;
    font-family: League Spartan, Cyrillic !important;
    margin: 1rem 0 0 !important;
    font-size: 16px;
    cursor: pointer;
    background-color: ${({ theme }) => theme.primaryButton} !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;
    text-align: center;
    color: white;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
    background-image: url(/assets/img/btn_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    transition: all 0.2s;
`;
export const ProfileSaveButton = styled(Button)`
    min-width: 100px;
    font-family: League Spartan, Cyrillic !important;
    font-size: 16px;
    cursor: pointer;
    background-color: ${({ theme }) => theme.primaryButton} !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;
    text-align: center;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
    color: #ffffff !important;
    background-image: url(/assets/img/btn_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    transition: all 0.2s;
    @media (max-width: 500px) {
        font-size: 12px;
    }
`;
export const TabPanelWrapp = styled(TabPanel)`
    padding: 24px 0;
`;
export const IntroTitle = styled(Typography)`
    font-family: PT Sans !important;
    text-align: center;
    color: ${({ theme }) => theme.text};
    margin: 1rem;
    user-select: none;
    font-family: PT Sans !important;
    font-size: 1.2rem;
    margin-bottom: 0;
`;
export const PromoInputPart = styled.div`
    position: relative;
`;
export const LinearProgressWarp = styled(LinearProgress).attrs(
    (props: any) => ({
        status: props.status
    })
)`
    margin-top: 0.5rem !important;
    height: 0.5rem;
    border-radius: 10px;
    span {
        background: ${(props: any) => props.status} !important;
    }
`;
export const BetType = styled(Box).attrs((props: any) => ({
    type: props.type
}))`
    background: ${(props: any) => (props.type === 'win' || props.type === 'deposit' ? '#0fbb50' : 'red')};
    text-align: center;
    border-radius: 10px;
    color: white;
    width: ${(props) => props.type.length > 3 ? '70px' : '40px'};
`;
export const TableContainerWrap = styled(TableContainer)({
    paddingTop: '1rem'
});
export const IntroContentTop1 = styled(Box)({
    boxShadow: '0 1px 3px rgb(0 0 0 / 40%)',
    padding: '0 2rem 2rem 2rem',
    background: '#272a37',
    marginBottom: '1rem',
    '@media (max-width: 1024px)': {
        padding: '1rem',
        width: 'calc(100% - 2rem)'
    },
    '& p, & div, & li, & label': {
        fontFamily: 'PT Sans !important'
    }
});
export const ExtendedLetterInput = styled(LetterInput)`
    color: ${({ theme }) => theme.borderColor} !important;
    & .MuiInputAdornment-root {
        p {
            color: ${({ theme }) => theme.text} !important;
        }
    }
`;
export const Letter = styled(Typography)`
    text-align: center;
    margin: 2rem 0 0 !important;
    user-select: none;
    font-size: 20px !important;
    font-weight: 700;
    color: ${(props) => props.theme.text};
    font-family: 'PT Sans' !important;
`;
export const PasswordInput = styled(TextField)`
    width: 100%;
    margin-top: 1rem !important;
    color: ${(props) => props.theme.borderColor} !important;
    & input:-webkit-autofill,
    & input:-webkit-autofill:hover,
    & input:-webkit-autofill:focus,
    & input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: ${(props) => props.theme.borderColor};
    }
    & textarea {
        color: ${(props) => props.theme.borderColor};
    }

    & .MuiInputBase-input:-webkit-autofill {
        color: ${(props) => props.theme.borderColor};
        font-family: 'PT Sans' !important;
        & :-webkit-autofill:active {
            -webkit-box-shadow: unset;
            color: ${(props) => props.theme.borderColor};
        }
        & :-webkit-autofill {
            background-color: transparent;
        }
        -webkit-text-fill-color: ${(props) => props.theme.borderColor};
    }

    & label.Mui-focused {
        color: ${(props) => props.theme.borderColor};
    }

    &.Mui-focused {
        color: ${(props) => props.theme.borderColor};
    }

    & label {
        color: ${(props) => props.theme.borderColor};
        font-family: 'PT Sans' !important;
    }

    & svg {
        color: ${(props) => props.theme.borderColor};
    }

    & input {
        color: ${(props) => props.theme.borderColor};
        &:focus {
            color: ${(props) => props.theme.borderColor};
        }

        & input:-internal-autofill-selected {
            background-color: transparent;
            color: ${(props) => props.theme.borderColor};
        }

        & input:-webkit-autofill {
            background: transparent;
        }
    }
    & .MuiOutlinedInput-root {
        & fieldset {
            border-color: ${(props) => props.theme.borderColor};
        }

        &:hover fieldset {
            border-color: ${(props) => props.theme.borderColor};
        }

        &.Mui-focused fieldset {
            border-color: ${(props) => props.theme.borderColor};
        }
    }
`;
export const B8866Loading = styled(Box)`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: #ffffff45;
    z-index: 99999999;
    user-select: none;

    @keyframes loadingE {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;
export const Load5 = styled(Box)`
    animation: loadingE 3s linear infinite;
    user-select: none;
`;
export const Ring2 = styled(Box)`
    position: relative;
    width: 55px;
    height: 55px;
    margin: 0 auto;
    border: 4px solid #fa0;
    border-radius: 100%;
    user-select: none;
`;
export const BallHolder = styled(Box)`
    position: absolute;
    width: 12px;
    height: 55px;
    left: calc(50% - 6px);
    top: 0;
    animation: loadingE 1.3s linear infinite;
    user-select: none;
`;
export const Ball = styled(Box)`
    position: absolute;
    top: -11px;
    left: 0;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background: #fa0;
    user-select: none;
`;
