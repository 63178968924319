import styled, { css } from 'styled-components';
import { Box, Grid, Typography, Button, Tab } from '@mui/material';
import { Search } from '@mui/icons-material';
import { FullScreen } from 'react-full-screen';
import MenuItem from '@mui/material/MenuItem';

export const Lobby = styled(Box).attrs((props: any) => ({
    img: props.img
}))`
    background: url('${(props: any) => props.img}');
    ${({ theme }) =>
        theme.mode !== 'dark'
            ? css`
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-position: center;
                  background-attachment: fixed;
              `
            : null}
    position: relative;
    display: flex;
    justify-content: center;
    &:before {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 100%;
    }
    @media only screen and (max-width: 1200px) {
        .MuiContainer-root {
            padding: 0 !important;
        }
    }
    @media only screen and (max-width: 780px) {
        background: unset;
    }
`;
export const GameContent = styled(Box)`
    width: calc(100% - 2rem);
    background: transparent;
    font-family: 'Open Sans', sans-serif;
    position: relative;
    clear: both;
    border-bottom: none;
    border-color: #c8c8c8 !important;
    position: sticky;
    top: 0;
    z-index: 9;
    box-sizing: border-box;
    text-shadow: none;
    margin: 1rem;
    min-height: 500px;
    .MuiTabs-indicator {
        background: ${({ theme }) => theme.primaryButton} !important;
    }
    .MuiLinearProgress-root {
        background: #272a37 !important;
    }
    .MuiLinearProgress-bar {
        background: ${({ theme }) => theme.primaryButton} !important;
    }
    @media only screen and (max-width: 1250px) {
        margin: 0;
        padding: 0 0.2rem;
    }
    @media only screen and (max-width: 800px) {
        padding: 0 0.5rem;
        width: 100%;
    }
`;
export const SkeletonPlaceholder = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.05);
    height: 100%;
    
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 0.1;
        }
    }

    img {
        animation: fadeIn 1s infinite alternate;
        width: 100%;
        height: auto !important;
    }
`;

export const SearchBar = styled(Box)`
    width: 100% !important;
    background: ${(props) => props.theme.background.secondary};
    box-shadow: 0 1px 3px #555 inset;
    width: 100%;
    display: flex;
    border-radius: 4px;
    @media only screen and (max-width: 769px) {
        flex-direction: column;
        align-items: center;
    }
`;
export const ProviderList = styled(Box)`
    width: 60%;
    padding: 0.4rem 1rem 0 1rem;
    display: flex;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    & .MuiTabs-scrollButtons {
        color: ${({ theme }) => theme.text};
    }
    @media only screen and (max-width: 769px) {
        width: 100%;
        margin-right: 0.5rem;
    }
`;
export const ProviderItem = styled(Tab).attrs((props: any) => ({
    flag: props.flag
}))`
    border-right: none;
    border-left: none;

    text-transform: capitalize !important;
    font-family: League Spartan, Cyrillic !important;
    font-weight: 500 !important;
    font-size: 0.875rem;

    padding: 4px 6px;
    color: ${(props: any) =>
        props.flag === 'true'
            ? props.theme.primaryButton
            : props.theme.text} !important;
    cursor: pointer;
    margin: 0.1rem;
    user-select: none;
    transition: all 0.2s;
    button {
        padding: 0 !important;
    }
    &:hover {
        font-size: 0.8rem;
    }
`;

export const SubItem = styled(Box)`
    display: flex;
    i {
        display: inline-block;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 20px;
        margin-right: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media only screen and (max-width: 1310px) {
            font-size: 18px !important;
        }
    }
    span {
        padding-top: 5px;
    }
    img {
        width: 25px;
        height: 25px;
        margin-right: 0.2rem;
    }
`;

export const ProviderInput = styled(Grid)`
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.8rem 0.4rem 0.4rem 0.4rem;
    position: relative;
    @media only screen and (max-width: 769px) {
        width: 100%;
    }
    .MuiInputBase-root {
        padding: 0 !important;
        color: ${(props) => props.theme.borderColor} !important;
    }
    .search-filed {
        min-width: unset;
    }
    & label {
        color: ${(props) => props.theme.borderColor} !important;
        -webkit-text-fill-color: ${(props) =>
            props.theme.borderColor} !important;
    }
    & input {
        color: ${(props) => props.theme.borderColor} !important;
        -webkit-text-fill-color: ${(props) =>
            props.theme.borderColor} !important;
        outline: none;
    }
    & fieldset {
        border-color: ${(props) => props.theme.borderColor} !important;
    }
    & .MuiPaper-root {
        background: ${(props) => props.theme.background.secondary};
    }
    & .MuiAutocomplete-popper .MuiAutocomplete-listbox {
        color: ${(props) => props.theme.borderColor} !important;
        //padding: 0;
        background: ${(props) => props.theme.background.secondary} !important;
    }

    & .MuiAutocomplete-option {
        color: ${(props) => props.theme.borderColor};
        background: ${(props) => props.theme.background.secondary} !important;
    }
    @media screen and (max-width: 400px) {
        .search-filed {
            min-width: unset;
            .MuiInputBase-root {
                max-width: 135px;
            }
        }
    }
`;

export const SelectMenuItem = styled(MenuItem)`
  color: ${({ theme }) => theme.borderColor};
  background-color: ${({ theme }) => theme.background.secondary};
  
  &:hover {
    background-color: ${({ theme }) => theme.background.secondary};
  }
  
  &&.Mui-selected { 
    background-color: ${({ theme }) => theme.background.secondary} ;
  }
`;
export const SearchIcon = styled(Search)`
    color: ${(props) => props.theme.text};
    display: flex;
    align-items: center;
    z-index: 1;
`;

export const GameLists = styled(Grid)`
    margin-top: 0 !important;
    p,
    span {
        font-family: PT Sans !important;
    }
`;
export const GameItem = styled(Grid)``;

export const GameItem1 = styled(Box)`
    background-color: #272a37;
    color: #fff;
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba (0, 0, 0, 0.2),
        0px 1px 1px 0px rgba (0, 0, 0, 0.14),
        0px 1px 3px 0px rgba (0, 0, 0, 0.12);
    background-image: linear-gradient(
        rgba(255, 255, 255, 0.05),
        rgba(255, 255, 255, 0.05)
    );
    overflow: hidden;
    height: 100%;
    position: relative;
    img {
        width: 100%;
        height: 100%;
        transition: 0.75s;
    }
    &:hover {
        .cover {
            background: rgba(0, 0, 0, 0.5);
            width: 100%;
            border-radius: 0;
            top: 0;
        }
        img {
            transform: rotate(10deg) scale(1.5);
        }
    }
`;

export const CoverBox = styled(Box)`
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in 0s !important;
    position: absolute;
    border-radius: 50%;
    top: 100%;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: gray;
`;
export const ProviderName = styled(Typography)`
    font-family: PT Sans !important;
    font-size: 0.9rem !important;
    font-weight: bold;
    line-height: inherit !important;
    user-select: none;
    margin-bottom: 0.5rem;
    @media (max-width: 1250px) {
        color: ${({ theme }) =>
            theme.mode === 'white' && theme.text} !important;
    }
`;
export const GameName = styled(Typography)`
    font-family: PT Sans !important;
    font-size: 1.2rem !important;
    font-weight: bold;
    line-height: inherit !important;
    user-select: none;
    margin: 0.5rem 0;
    @media only screen and (max-width: 1450px) {
        font-size: 1rem !important;
    }
`;
export const GameName1 = styled(Typography)`
    font-size: 1.2rem !important;
    font-weight: bold;
    line-height: inherit !important;
    user-select: none;
    span {
        color: #fa0;
    }
`;
export const GameBtn = styled(Box)`
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0 1rem;
    @media only screen and (max-width: 1400px) {
        margin: 0;
    }
`;
export const PlayGame = styled(Button)`
    background: ${({ theme }) => theme.primaryButton} !important;
    font-family: League Spartan, Cyrillic !important;
    padding-top: 0.7rem;
    @media only screen and (max-width: 1450px) {
        font-size: 0.6rem !important;
    }
`;

export const GameGridItem = styled(Grid)`
    display: flex;
    justify-content: center;
`;

export const RegisterBtn = styled(Button)`
    font-size: 16px;
    width: auto;
    margin-left: 0.5rem !important;
    cursor: pointer;
    background-color: ${({ theme }) => theme.primaryButton} !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    text-align: center;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
    color: #fff;
    cursor: pointer;
    background-image: url(/assets/img/btn_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    transition: all 0.2s;
    &:hover {
        font-size: 15px;
    }
    @media only screen and (max-width: 500px) {
        font-size: 12px;
        margin-right: 0.5rem;
    }
    a {
        color: #fff !important;
        text-decoration: unset !important;
    }
`;
export const GamePlayPage = styled.div.attrs((props: any) => ({
    url: props.url
}))`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    @media only screen and (max-width: 1250px) {
        justify-content: unset;
        padding: ${(props) => (props.url === 'false' ? '0' : '2rem')};
        height: ${(props) =>
            props.url === 'false' ? '100%' : 'calc(100% - 4rem)'};
    }
`;

export const BalanceIn = styled(Typography)`
    color: white;
    user-select: none;
    @media (max-width: 1250px) {
        color: ${({ theme }) =>
            theme.mode === 'white' && theme.text} !important;
    }
`;
export const BalancePart = styled(Box)`
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.3rem 0;
    user-select: none;
    @media (max-width: 1250px) {
        color: ${({ theme }) =>
            theme.mode === 'white' && theme.text} !important;
    }
    svg {
        font-size: 1.5rem;
    }
`;
export const BalanceImg = styled.img`
    padding: 0 0.5rem;
    width: 25px;
    margin: 0 !important;
    user-select: none;
`;
export const Balance = styled(Typography)`
    color: white;
    user-select: none;
    @media (max-width: 1250px) {
        color: ${({ theme }) =>
            theme.mode === 'white' && theme.text} !important;
    }
`;
export const ButtonPart = styled(Box)`
    display: flex;
    justify-content: center;
    button {
        margin: 0.5rem;
    }
`;
export const GameHeader = styled.div.attrs((props: any) => ({
    allScreen: props.allScreen
}))`
    margin: 0;
    height: 5rem;
    background: ${(props) => props.theme.background.primary};
    align-items: center;
    justify-content: flex-end;
    padding: 0 1rem;
    //width: 100%;
    display: ${(props) => (props.allScreen === 'true' ? 'none' : 'flex')};
    @media only screen and (max-width: 1250px) {
        display: none;
    }
`;
export const FullScreenWrapp = styled(FullScreen)`
    position: absolute;
    width: 100% !important;
    height: 100vh !important;
    background: ${(props) => props.theme.background.primary};
    display: flex;
    z-index: 999999 !important;
    @media only screen and (max-width: 1024px) {
        padding: 0;
        width: 100% !important;
        height: 100vh !important;
    }
`;
export const SoftTopId = styled.div.attrs((props: any) => ({
    img: props.img,
    platform: props.platform,
    allScreen: props.allScreen
}))`
    width: 100%;
    flex: ${(props) => props.allScreen === 'true' ? '1 1 50%;': '1 1 49%;'}
    background: #000000;
    position: relative;
    overflow: hidden;
    @media only screen and (max-width: 1250px) {
        width: 100%;
        background: url('${(props: any) => props.img}');
        ${({ theme }) =>
            theme.mode !== 'dark'
                ? css`
                      background-size: cover;
                      background-repeat: no-repeat;
                      background-position: center;
                      background-attachment: fixed;
                  `
                : null}
    }
`;
export const GameBox = styled(Box).attrs((props: any) => ({
    img: props.img
}))`
    width: 100%;
    height: 100%;
    background: url('${(props: any) => props.img}');
    ${({ theme }) =>
        theme.mode !== 'dark'
            ? css`
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-position: center;
                  background-attachment: fixed;
              `
            : null}
`;
export const HeaderBox = styled(Box)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    user-select: none;
`;
export const HeaderBox2 = styled(Box)`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    max-width: calc(100% / 2);
`;
export const User = styled(Box)`
    background: ${(props) => props.theme.background.secondary};
    border-radius: 8px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 40%);
    display: flex;
    align-items: center;
`;
export const Logo = styled.img`
    max-width: 150px;
    cursor: pointer;
    position: absolute;
    left: calc(50% - 75px);
    @media only screen and (max-width: 1600px) {
      left: 40px;
    }
`;
export const TopIdHeader = styled.div.attrs((props: any) => ({
    allScreen: props.allScreen,
    openWidget: props.openWidget
}))`
    width: 100%;
    max-width: ${(props) => (props.allScreen === 'true' ? '100%' : '1500px')};
    display: flex;
    align-items: flex-start;
    padding: 0;
    margin: ${(props) => (props.allScreen === 'true' ? '0' : '0 auto')};
    flex-wrap: wrap;
    height: ${(props) => (props.allScreen === 'true' ? '100%' : '89%')};
    position: relative;
    transition: all 0.3s;
    @media only screen and (max-width: 1500px) {
        max-width: ${(props) => (props.allScreen === 'true' ? '100%' : '1200px')};
    }
    @media only screen and (max-width: 1250px) {
        padding: 0;
        height: 100%;
    }
`;
export const MobileGameBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
        max-width: 400px;
        margin: 1rem 0;
        @media only screen and (min-width: 400px) {
            width: 100%;
        }
    }
    @media only screen and (min-width: 1251px) {
        display: none;
    }
`;
export const DesktopGameBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
        max-width: 400px;
        margin: 1rem 0;
        @media only screen and (min-width: 400px) {
            width: 100%;
        }
    }
    @media only screen and (max-width: 1251px) {
        display: none;
    }
`;
export const HeaderBox3 = styled(Box)`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    svg {
        color: white;
        @media (max-width: 1250px) {
            color: ${({ theme }) =>
                theme.mode === 'white' && theme.text} !important;
        }
    }
`;
export const HeaderBox4 = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
`;
export const BonusBalanceShowPart = styled(Box)`
    font-family: PT Sans !important;
    font-weight: bold;
    color: ${(props) => props.theme.text};
    font-size: 1rem;
    user-select: none;
    line-height: unset;
    display: flex;
    align-items: center;
    padding: 0.7rem 0.5rem 0.7rem 1rem;
    cursor: pointer;
    transition: all 0.5s;
    p {
        margin: 0;
        text-align: center;
        @media only screen and (max-width: 500px) {
            font-size: 14px !important;
        }
    }
    svg {
        font-size: 2rem;
    }
`;

export const AccordionsWrapper = styled.div`
    width: 100%;
    max-width: fit-content;
    display: flex;
    justify-content: flex-start;
    position: absolute;
    top: 8%;
    transition: all 0.3s;
    height: 100%;
    right: 0;
    z-index: ${(props: { openWidget: boolean }) =>
        props.openWidget === true ? '100' : '10'};
    transform: translateX(
        ${(props: { openWidget: boolean }) =>
            props.openWidget === true ? '1%' : '88%'}
    );
    @media (max-width: 1250px) {
        display: none;
    }
`;

export const WidgetsWrapper = styled.div`
    width: 100%;
    max-width: 320px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    z-index: 100;
    background: ${({ theme }) => theme.background.primary};
`;

export const ShowMoreBtn = styled.div`
    writing-mode: vertical-rl;
    height: fit-content;
    background: ${({ theme }) => theme.background.secondary};
    color: ${({ theme }) => theme.text};
    font-family: 'PT Sans', sans-serif;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    transform: translateY(50px);
`;

export const searchInputStyles = {
    fontFamily: 'PT Sans !important',
    userSelect: 'none',
    fontSize: '0.875rem',
    textAlign: 'left',
    width: '100%',
    height: '100%',
    border: 'none',
    boxShadow: '0 1px 2px #202330 inset',
    maxWidth: '220px',
    minWidth: '200px',
    borderRadius: '4px',
    outline: 'none',
    justifyContent: 'center',
    '& .MuiInputBase-root': {
        color: 'rgb(216,216,216) !important',
        margin: '0 12px'
    },
    '& input': {
        fontWeight: 'bold',
        fontSize: '0.875rem'
    }
};