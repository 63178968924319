import { Fragment } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import ArrowForward from '@mui/icons-material/ArrowForward';

import useBaseZustand from '../../../zustand/BaseZustand';
import useAuthZustand from '../../../zustand/AuthZustand';

import { Root } from '../../../config/config';

import {
    MainSidebarChapterWrapper,
    MainSidebarItem,
    MainSidebarItemTitle,
    MainSidebarTitle,
    OpenCloseButtonWrapper,
    MainSidebarScroll,
} from './style';

import { ISidebarProps } from './interface';

import { useTheme } from 'styled-components';
import { Theme } from '../../../theme';

const ExtendedSidebar = ({
    currentItem,
    handleChooseItem,
}: ISidebarProps) => {
    const theme = useTheme() as Theme;
    const { t } = useTranslation();

    const { isDesktopSidebar, setDesktopSidebar } = useBaseZustand(
        (state) => state
    );
    const { sidebar } = useAuthZustand((state) => state);

    const handleOpen = () => {
        if (isDesktopSidebar === true) {
            setDesktopSidebar(false);
        } else {
            setDesktopSidebar(!isDesktopSidebar);
        }
    };

    return (
        <>
            <OpenCloseButtonWrapper open={isDesktopSidebar}>
                <Tooltip title={t('Collapse Menu')} placement="right-start">
                    <ArrowForward
                        onClick={handleOpen}
                        sx={{ color: theme.text }}
                    />
                </Tooltip>
            </OpenCloseButtonWrapper>
            <MainSidebarScroll open={isDesktopSidebar}>
                {sidebar.map((item, key) => (
                    <Fragment key={key}>
                        <MainSidebarChapterWrapper>
                            <MainSidebarTitle open={isDesktopSidebar}>
                                {t(item.name)}
                            </MainSidebarTitle>
                        </MainSidebarChapterWrapper>
                        {item.children
                            .filter((it) => it.issidebar === true)
                            .map((menuItem, menuKey) => (
                                <Fragment key={menuKey}>
                                    <MainSidebarItem
                                        url={
                                            currentItem === menuItem.url &&
                                            currentItem
                                        }
                                        onClick={() =>
                                            handleChooseItem(menuItem.url)
                                        }
                                    >
                                        <img
                                            src={`${Root.serverUrl}${menuItem.image}`}
                                            alt=""
                                        />
                                        <MainSidebarItemTitle
                                            open={isDesktopSidebar}
                                        >
                                            {t(menuItem.name)}
                                        </MainSidebarItemTitle>
                                    </MainSidebarItem>
                                </Fragment>
                            ))}
                    </Fragment>
                ))}
            </MainSidebarScroll>
        </>
    );
};

export default ExtendedSidebar;
