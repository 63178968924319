import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import BaseZustand from '../../../zustand/BaseZustand';
import useAuthZustand from '../../../zustand/AuthZustand';

import Header from '../Header';
import Footer from '../Footer/MainFooter';
import { Root } from '../../../config/config';

import Intl from '../Header/Intl';

import {
    LeftSidebar,
    GameItemContent,
    GameGridItem,
    GameItem,
    GameName,
    IntlContent
} from './style';
import { Fragment } from 'react';
import ThemeSwitch from '../../../theme/ThemeSwitch';

const MobileSidebar = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { setSidebar } = BaseZustand((state) => state);
    const { sidebar } = useAuthZustand((state) => state);

    const changePageOnSidebar = async (url) => {
        history.push(url)
        setSidebar(false);
    };

    return (
        <LeftSidebar>
            <Header />
            <GameItemContent container>
                {sidebar
                    .find((item) => item.name === 'For You')
                    ?.children.map((gameType, key) => (
                        <Fragment key={key}>
                            {gameType.ismobile ? (
                                <GameGridItem sm={3} xs={4} item key={key}>
                                    <GameItem
                                        onClick={() =>
                                            changePageOnSidebar(gameType.url)
                                        }
                                    >
                                        <img
                                            src={`${Root.serverUrl}${gameType.image}`}
                                            alt=""
                                        />
                                        <GameName>{t(gameType.name)}</GameName>
                                    </GameItem>
                                </GameGridItem>
                            ) : (
                                <></>
                            )}
                        </Fragment>
                    ))}
            </GameItemContent>
            <IntlContent>
                <Intl />
                <ThemeSwitch />
            </IntlContent>
            <Footer />
        </LeftSidebar>
    );
};

export default MobileSidebar;
