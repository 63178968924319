import styled from 'styled-components';

export const MultipleGamesWrapper = styled.div.attrs(
    (props: { isFullScreen: boolean }) => ({
        isFullScreen: props.isFullScreen,
    })
)`
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: ${(props) => props.isFullScreen? '0' : '5px' };
`;

export const MultipleGameCurrentItemHeader = styled.header<{
    expanded?: boolean;
    fullSize?: boolean;
}>`
    width: 100%;
    position: absolute;
    height: 10px;
    top: ${(props) => (props.expanded ? '0px' : '5px')};
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-content: flex-end;
    z-index: 2;
    transition: all 0.3s;
    border-radius: 0 0 6px 6px;
    cursor: pointer;
    backface-visibility: hidden;
    background: transparent;

    & > * {
        transition: all 0.3s;
        transform: scale(0);
    }

    &:hover {
        top: 0;

        height: 60px;
        padding-bottom: 10px;
        & > * {
            opacity: 1;
            transform: scale(1);
        }
    }
    @media (max-width: 1250px) {
        display: none;
    }
`;

export const MultipleGameCurrentItemHeaderInfoWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const MultipleGameCurrentItemGameName = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'PT Sans', sans-serif;
    & > h1 {
        width: 100%;
        margin: 0 10px;
        font-size: 20px;
        text-align: center;
        color: ${({ theme }) =>
            theme.mode === 'white' ? theme.background.primary : theme.text};
    }
    & > h3 {
        width: 100%;
        margin: 0 10px;
        font-size: 15px;
        text-align: center;
        color: ${({ theme }) => theme.primaryButton};
    }
    & span {
        color: ${({ theme }) =>
            theme.mode === 'white' ? theme.background.primary : theme.text};
        font-weight: 500;
    }
`;
