import { connect } from 'socket.io-client';
import create from 'zustand';
import { toast } from 'react-toastify';
import { Root } from '../config/config';
import AuthApi from '../api/AuthApi';
import ConfigApi from '../api/ConfigApi';
import { ToastBox } from '../pages/ui-element/toast';
import BaseZustand from './BaseZustand';
import { defaultIdValue } from '../config/mainConfig';

const useStore = create((set) => ({
    isAuth: false,
    logoImage: '',
    sidebar: [],
    sliders: [],
    footers: [],

    user: {
        id: '',
        username: '',
        email: '',
        phone: '',
        firstname: '',
        lastname: '',
        country: '',
        state: '',
        street: '',
        city: '',
        zipcode: '',
        gender: '',
        language: '',
        currency: '',
        birthday: '',
        status: 'allow',
        parentid: ''
    },
    balance: 0,
    balanceList: [],
    countryData: [],
    languageData: [],

    notifyData: [],    
    currencies: [],
    addtionalProviderConfig: {},

    sessionCheck: async () => {
        let token = AuthApi.getToken();

        const siteConfigSet = (siteConfig) => {
            let link = document.querySelector("link[rel~='icon']");
            link.href = `${Root.serverUrl}${siteConfig.favicon}`;

            let link1 = document.querySelector("link[rel~='apple-touch-icon']");
            link1.href = `${Root.serverUrl}${siteConfig.favicon}`;

            document.title = siteConfig.sitename;
            set({ logoImage: siteConfig.logo });
        };

        const siteSidebarSet = (siteSidebar) => {
            const data = [];
            for (const item of siteSidebar) {
                if (item.pid === defaultIdValue) {
                    data.push({ ...item, children: [] });
                } else {
                    const pIndex = data.findIndex((it) => it.id === item.pid);
                    data[pIndex].children.push(item);
                }
            }
            set({ sidebar: data });
        };

        const siteThemeSet = (siteDomain) => {
            if (siteDomain && siteDomain.detail && siteDomain && siteDomain.detail.color) {
                localStorage.setItem('theme', siteDomain.detail.color)
            }
        }

        const baseInit = async () => {
            const crdata = await ConfigApi.getPlayerBaseData({
                site: Root.site
            });

            const {
                siteConfig,
                siteSidebar,
                siteSlider,
                siteFooter,
                siteDomain
            } = crdata.data.data;
            if (siteConfig) siteConfigSet(siteConfig);
            
            siteSidebarSet(siteSidebar);
            siteThemeSet(siteDomain);
            set({
                sliders: siteSlider,
                footers: siteFooter
            });
        };

        const baseDataInit = async () => {
            const crdata = await ConfigApi.getPlayerBaseInfo();
            const { countries, languages } = crdata.data.data;
            set({
                countryData: countries,
                languageData: languages
            });
        };

        const socketInit = async (userid) => {
            Root.socket = connect(Root.serverUrl, { query: { id: userid } });

            Root.socket.off('balance');
            Root.socket.on('balance', (data) => {
                set({
                    balance: data.balance
                });
            });
            Root.socket.off('expire');
            Root.socket.on('expire', () => {
                toast.error(<ToastBox letter="Session Expired" />);
                AuthApi.clearToken();
                window.location.reload();
            });
        };

        await baseInit();
        if (token) {
            const { data } = await AuthApi.sessionCheck();
            set({
                user: data.data.user,
                balance: data.data.balance,
                balanceList: data.data.balanceList,
                isAuth: true
            });
            baseDataInit();
            socketInit(data.data.user.id);
        }
    },
    userLogin: async (loginData) => {
        const { data } = await AuthApi.userLogin(loginData);
        const { setAuthModal } = BaseZustand.getState();
        const { sessionCheck } = useStore.getState();

        if (data && data.data) {
            toast.success(<ToastBox letter={data.message} />);
            AuthApi.setToken(data.data.accessToken);
            setTimeout(async () => {
                await sessionCheck();
                return setAuthModal({ authModal: { login: false } });
            }, 500);
        }
    },
    sessionUpdate: async () => {
        if (AuthApi.getToken()) {
            const { data } = await AuthApi.sessionCheck();
            set({
                user: data.data.user,
                balance: data.data.balance,
                balanceList: data.data.balanceList
            });
        }
    },
    userLogout: async () => {
        await AuthApi.userLogout();
        AuthApi.clearToken();
        window.location.assign('/home');
    },
    getMyNotification: async () => {
        const res = await ConfigApi.getMyNotification();
        if (res) {
            set({ notifyData: res.data });
        }
    }
}));

export default useStore;
