import { useTranslation } from 'react-i18next';
import Media from 'react-media';
import Container from '@mui/material/Container';

import AuthZustand from '../../../../zustand/AuthZustand';

import { Root } from '../../../../config/config';

import {
    Footer,
    FooterContent,
    FooterLink,
    Logo,
    FooterText,
    FooterRule,
    FooterRule1
} from './style';

const MainFooterPage = ({ show = true }) => {
    const { t } = useTranslation();
    const { logoImage } = AuthZustand((state) => state);

    return (
        <Footer show={String(show)}>
            <Container maxWidth="xl">
                <FooterContent container spacing={1}>
                    <Media
                        queries={{
                            large: '(min-width: 769px)',
                            small: '(max-width: 768px)'
                        }}
                    >
                        {(matches) => (
                            <>
                                {matches.small && (
                                    <FooterLink>
                                        <Logo
                                            src={`${Root.serverUrl}${logoImage}`}
                                            alt=""
                                        />
                                    </FooterLink>
                                )}
                            </>
                        )}
                    </Media>
                </FooterContent>
                <FooterContent>
                    <FooterText>
                        We are a premier online casino and sports betting
                        platform, offering an exciting range of games, live
                        casino, and sports betting options. Whether you’re here
                        for slots, poker, or the latest match odds, we bring the
                        thrill of gambling right to your fingertips. We are
                        committed to promoting responsible gaming. Please play
                        within your limits. If you or someone you know has a
                        gambling problem, seek help from professionals or use
                        the tools we provide to manage your gaming habits.
                    </FooterText>
                </FooterContent>
                <FooterContent container>
                    <FooterRule1>
                        <img
                            src={`${Root.serverUrl}/image/site/rule/18plus.svg`}
                            alt=""
                        />
                    </FooterRule1>
                    <FooterRule>
                        <img
                            src={`${Root.serverUrl}/image/site/rule/gamecare.png`}
                            alt=""
                        />
                    </FooterRule>
                    <FooterRule>
                        <img
                            src={`${Root.serverUrl}/image/site/rule/111.png`}
                            alt=""
                        />
                    </FooterRule>
                </FooterContent>

                <FooterContent container>
                    <FooterText>
                        @2020 - {new Date().getFullYear()}&nbsp;{Root.site}
                        .&nbsp;{t('All rights reserved.')}
                    </FooterText>
                </FooterContent>
            </Container>
        </Footer>
    );
};

export default MainFooterPage;
