export const UserDropDownList = [
    {
        name: 'My Profile',
        url: '/profile'
    },
    {
        name: 'Balance History',
        url: '/balance-history'
    },
    {
        name: 'Logout'
    }
];
