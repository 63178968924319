import APIAbstract from './APIAbstract';
import { Root } from '../config/config';

class AuthApi extends APIAbstract {
    // Authentication part
    sessionCheck() {
        return this.get('/player/auth');
    }
    userLogin(data) {
        return this.post('/player/auth/signin', data);
    }
    userLogout() {
        return this.delete('/player/auth/logout');
    }
}

const AuthClass = new AuthApi(`${Root.serverUrl}`);

export default AuthClass;
