import { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';

import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';

import { LoadingContext } from '../../../layout/context/loading';
import GameZustand from '../../../zustand/GameZustand';
import AuthZustand from '../../../zustand/AuthZustand';
import BaseZustand from '../../../zustand/BaseZustand';

import useWindowDimensions from '../../../hooks/useWindowDimensions';
import {
    MultipleGameCurrentItemGameName,
    MultipleGameCurrentItemHeader,
    MultipleGameCurrentItemHeaderInfoWrapper
} from './style';
import * as S from '../style';
import { Theme } from '../../../theme';
import {
    generateBackgroundImageByThemeMode,
    getGameImageSrc
} from '../../../helpers';
import { GameItemData } from '../types';

const GamePage = ({
    setGameModal,
    setAllScreen,
    handle,
    allScreen
}) => {
    const theme = useTheme() as Theme;
    const history = useHistory();
    const { t } = useTranslation();
    const { showLoading, hideLoading } = useContext(LoadingContext);

    const {
        game,
        provider,
        getGameUrl,
    } = GameZustand((state) => state);

    const { balance, isAuth } = AuthZustand((state) => state);
    const { setAuthModal } = BaseZustand((state) => state);
    const [url, setUrl] = useState('');

    const dimensions = useWindowDimensions();
    const backgroundImage = generateBackgroundImageByThemeMode(theme.mode);

    useEffect(() => {
        if (!game.id) {
            history.goBack();
        }
    }, [game]);

    const OpenGame = async () => {
        if (!isAuth) {
            toast.warn(t('Please login'));
            setAuthModal({ login: true });
            return;
        }
        if (balance > 0) {
            showLoading();
            const data = await getGameUrl({ id: game.id });
            if (!data) {
                hideLoading();
                return;
            }
            if (window.innerWidth < 1250) {
                window.location.replace(data.data);
            } else {
                setUrl(data.data);
            }
            hideLoading();
        } else {
            toast.warn(t('Please deposit money'));
        }
    };

    const closeFunc = () => {
        if (history.location.pathname === '/game') {
            history.goBack();
        } else {
            setGameModal(false);
            setAllScreen(false);
            handle.exit();
        }
    };

    return (
        <S.SoftTopId
            className={'game'}
            img={backgroundImage}
            platform={navigator.platform}
            allScreen={String(allScreen)}
            style={{
                height:
                    dimensions.clientWidth < 1250
                        ? dimensions.clientHeight
                        : null
            }}
        >
            <MultipleGameCurrentItemHeader>
                <MultipleGameCurrentItemHeaderInfoWrapper>
                    <MultipleGameCurrentItemGameName>
                        <h1>{game.name}</h1>
                        {provider ? <h3>{provider.label}</h3> : null}
                    </MultipleGameCurrentItemGameName>
                </MultipleGameCurrentItemHeaderInfoWrapper>
            </MultipleGameCurrentItemHeader>
            <S.GamePlayPage url={String(!url)} id="game">
                {!url ? (
                    <>
                        <S.MobileGameBox>
                            <S.HeaderBox3>
                                <IconButton onClick={closeFunc}>
                                    <Close />
                                </IconButton>
                            </S.HeaderBox3>
                            <S.HeaderBox4>
                                <S.GameName1>
                                    <span>{game.name}</span>
                                </S.GameName1>
                                <S.ProviderName>
                                    {provider.label}
                                </S.ProviderName>
                            </S.HeaderBox4>
                            <img
                                src={getGameImageSrc(
                                    game as unknown as GameItemData
                                )}
                                alt=""
                            />
                        </S.MobileGameBox>
                        <S.DesktopGameBox>
                            <img
                                src={getGameImageSrc(
                                    game as unknown as GameItemData
                                )}
                                alt=""
                            />
                        </S.DesktopGameBox>
                        <S.ButtonPart>
                            {!isAuth ? (
                                <S.RegisterBtn
                                    variant="contained"
                                    onClick={() =>
                                        setAuthModal(
                                            { login: true, register: false },
                                            history
                                        )
                                    }
                                >
                                    {t('LOGIN')}
                                </S.RegisterBtn>
                            ) : (
                                <></>
                            )}
                            {isAuth ? (
                                <S.RegisterBtn
                                    variant="contained"
                                    onClick={() => OpenGame()}
                                >
                                    {t('Play Game')}
                                </S.RegisterBtn>
                            ) : (
                                <></>
                            )}
                        </S.ButtonPart>
                    </>
                ) : (
                    <></>
                )}
                {url ? (
                    <iframe
                        src={url}
                        title="game"
                        style={{ width: '100%', height: '100%' }}
                        allowFullScreen
                    />
                ) : (
                    <></>
                )}
            </S.GamePlayPage>
        </S.SoftTopId>
    );
};

export default GamePage;
