import { useContext, useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { Modal } from 'react-responsive-modal';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';

import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { LoadingContext } from '../../layout/context/loading';

import AuthZustand from '../../zustand/AuthZustand';
import BaseZustand from '../../zustand/BaseZustand';

import { Theme } from '../../theme';
import { Letter, AccessButton, PasswordInput } from '../style';

const Login = () => {
    const theme = useTheme() as Theme;
    const { t } = useTranslation();
    const history = useHistory();
    const { showLoading, hideLoading } = useContext(LoadingContext);

    const { authModal, setAuthModal } = BaseZustand((state) => state);
    const { isAuth, userLogin } = AuthZustand((state) => state);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword1, setShowPassword1] = useState(false);

    useEffect(() => {
        const stateData = queryString.parse(history.location.search);

        if (stateData.uri === 'signin' && !isAuth && stateData.email) {
            setEmail(stateData.email.toString());
        }

        setTimeout(() => {
            document.getElementById('init-loading').style.display = 'none';
        }, 2000);

        // eslint-disable-next-line
    }, []);

    const login = async (e) => {
        e.preventDefault();
        if (!email) {
            toast.error(t('Please input email or username'));
            return;
        } else if (!password) {
            toast.error(t('Please input correct password'));
            return;
        }
        showLoading();
        await userLogin({ email: email.split(' ').join(''), password });

        hideLoading();
    };

    const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
    const handleMouseDownPassword1 = (event) => event.preventDefault();

    return (
        <Modal
            center={true}
            open={authModal && authModal.login}
            onClose={() => setAuthModal({ register: false, login: false })}
            styles={{
                modal: { background: theme.background.secondary },
                closeIcon: { fill: theme.text }
            }}
        >
                <Letter>{t('Sign In with your account')}</Letter>
                <form onSubmit={(e) => login(e)}>
                    <PasswordInput
                        label={t('Username')}
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        autoComplete="off"
                    />
                    <PasswordInput
                        type={showPassword1 ? 'text' : 'password'}
                        label={t('Password')}
                        variant="outlined"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="off"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword1}
                                        onMouseDown={handleMouseDownPassword1}
                                        edge="end"
                                    >
                                        {showPassword1 ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <AccessButton variant="outlined" type="submit">
                        {t('LOGIN')}
                    </AccessButton>
                </form>
        </Modal>
    );
};

export default Login;
