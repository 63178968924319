import styled from 'styled-components';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

export const MostPlayedGamesPopup = styled(Accordion)`
    width: 300px;
    background: ${({ theme }) => theme.background.secondary};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: 'PT Sans', sans-serif;
    color: ${({ theme }) => theme.text};
    & .MuiCollapse-root {
        width: 100%;
    }
    @media (max-width: 1250px) {
        display: none;
    }
`;

export const MostPlayedGamesPopupHeader = styled(AccordionSummary)`
    width: 100%;
    justify-content: space-between;
    min-height: 50px;
    position: relative;
    &.Mui-expanded {
        min-height: 50px;
    }
`;

export const MostPlayedGamesPopupContent = styled(AccordionDetails)`
    padding-top: 0;
    padding-bottom: 0;
    & .TournamentLeaderBoardWrapper {
        width: 100%;
        padding: 0;
        flex-direction: unset;
        & * {
            font-weight: 500;
            font-size: 14px !important;
        }
    }
`;

export const MostPlayedGamesItem = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    cursor: pointer;
`;

export const MostPlayedGamesTitle = styled.h3`
    margin: 0;
    font-size: 23px;
`;

export const MostPlayedGamesImageContainer = styled.div`
    width: 100%;
    max-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    & > img {
        width: 100%;
    }
`;

export const MostPlayedGamesGameTextWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 0 10px;
`;

export const MostPlayedGamesGameName = styled.h3`
    margin: 0;
`;

export const MostPlayedGamesProviderName = styled.p`
    margin: 0;
`;
