import create from 'zustand';
import ConfigApi from '../api/ConfigApi';

const useStore = create(() => ({
    readAllNotification: async () => {
        const res = await ConfigApi.readAllNotification();
        if (res) {
            return true;
        } else {
            return false;
        }
    },
    getMultilang: async () => {
        const rdata = await ConfigApi.getMultilang();
        if (rdata) {
            return rdata.data;
        } else {
            return [];
        }
    }
}));

export default useStore;
