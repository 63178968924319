import React, { useContext, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useFullScreenHandle } from 'react-full-screen';
import { useTranslation } from 'react-i18next';

import IconButton from '@mui/material/IconButton';
import CropFree from '@mui/icons-material/CropFree';
import Close from '@mui/icons-material/Close';
import ZoomInMap from '@mui/icons-material/ZoomInMap';

import AuthZustand from '../../../zustand/AuthZustand';
import BaseZustand from '../../../zustand/BaseZustand';

import GamePage from './gamePage';
import GameWidget from './Widgets';

import { RouterContext } from '../../../context/RouterContext';
import { generateBackgroundImageByThemeMode } from '../../../helpers';
import { Theme } from '../../../theme';

import { Root } from '../../../config/config';

import { MultipleGamesWrapper } from './style';
import * as S from '../style';

const GameModal: React.FC = () => {
    const { t } = useTranslation();
    const theme = useTheme() as Theme;
    const handle = useFullScreenHandle();
    const history = useHistory();

    const { user, balance, logoImage, isAuth } = AuthZustand((state) => state);
    const { gamemode, setGameModal } = BaseZustand((state) => state);

    const [isFullScreen, setIsFullScreen] = useState(false);
    const [openWidget, setOpenWidget] = useState(false);

    const routerHistory = useContext(RouterContext);
    const backgroundImage = generateBackgroundImageByThemeMode(theme.mode);
    
    const goBack = () => {
        try {
            if (history.location.pathname !== '/game') {
                history.goBack();
            } else {
                const previousLocation = routerHistory
                    .reverse()
                    .find(({ from, to }) => to === '/game' && from !== '/game');
                history.push(previousLocation.from);
            }            
        } catch (error) {
            console.log(error)
        }
    };

    const toggleFullScreen = () => {
        if (!isFullScreen) {
            handle.enter();
        } else {
            handle.exit();
        }
        setIsFullScreen(!isFullScreen);
    };

    useEffect(() => {
        if (history.location.pathname !== '/game') {
            setGameModal(false);
        }
    }, [history.location.pathname]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerHeight === window.screen.height) {
                setIsFullScreen(true);
            } else {
                setIsFullScreen(false);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const closeFunc = () => {
        setGameModal(false);
        setIsFullScreen(false);
        handle.exit();
        goBack();
    };

    return (
        <>
            {gamemode ? (
                <S.FullScreenWrapp handle={handle}>
                    <S.GameBox
                        onDoubleClick={toggleFullScreen}
                        img={backgroundImage}
                    >
                        <S.GameHeader allScreen={String(isFullScreen)}>
                            <S.HeaderBox2>
                                <S.HeaderBox onClick={closeFunc}>
                                    <S.Logo
                                        src={`${Root.serverUrl}${logoImage}`}
                                        onClick={() => history.push('/')}
                                        alt=""
                                    />
                                </S.HeaderBox>
                                {isAuth ? (
                                    <>
                                        <S.User>
                                            <S.BonusBalanceShowPart>
                                                <S.BalanceIn
                                                    style={{
                                                        color: theme.text
                                                    }}
                                                >
                                                    {t('Balance')}
                                                </S.BalanceIn>
                                                <S.BalancePart>
                                                    <S.BalanceImg
                                                        src={`https://s2.coinmarketcap.com/static/cloud/img/fiat-flags/${user.currency}.svg`}
                                                    />
                                                    <S.Balance
                                                        style={{
                                                            color: theme.text
                                                        }}
                                                    >
                                                        {balance}
                                                    </S.Balance>
                                                    &nbsp;
                                                    <S.Balance
                                                        style={{
                                                            color: theme.text
                                                        }}
                                                    >
                                                        {user.currency}
                                                    </S.Balance>
                                                </S.BalancePart>
                                            </S.BonusBalanceShowPart>
                                        </S.User>
                                    </>
                                ) : (
                                    <></>
                                )}
                                {!isFullScreen ? (
                                    <IconButton onClick={() => handle.enter()}>
                                        <CropFree sx={{ color: theme.text }} />
                                    </IconButton>
                                ) : (
                                    <IconButton onClick={() => handle.exit()}>
                                        <ZoomInMap sx={{ color: theme.text }} />
                                    </IconButton>
                                )}
                                <IconButton onClick={closeFunc}>
                                    <Close sx={{ color: theme.text }} />
                                </IconButton>
                            </S.HeaderBox2>
                        </S.GameHeader>
                        <S.TopIdHeader allScreen={String(isFullScreen)}>
                            <MultipleGamesWrapper isFullScreen={isFullScreen}>
                                <GamePage
                                    setGameModal={setGameModal}
                                    setAllScreen={setIsFullScreen}
                                    handle={handle}
                                    allScreen={isFullScreen}
                                />
                            </MultipleGamesWrapper>
                        </S.TopIdHeader>
                        {!isFullScreen ? (
                            <S.AccordionsWrapper openWidget={openWidget}>
                                <S.ShowMoreBtn
                                    onClick={() => setOpenWidget(!openWidget)}
                                >
                                    {openWidget
                                        ? t('Hide more')
                                        : t('Show more')}
                                </S.ShowMoreBtn>
                                <S.WidgetsWrapper>
                                    <GameWidget />
                                </S.WidgetsWrapper>
                            </S.AccordionsWrapper>
                        ) : null}
                    </S.GameBox>
                </S.FullScreenWrapp>
            ) : (
                <></>
            )}
        </>
    );
};

export default GameModal;
