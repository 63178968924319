import { Fragment, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAuthZustand from '../../../../zustand/AuthZustand';

import AuthZustand from '../../../../zustand/AuthZustand';
import BaseZustand from '../../../../zustand/BaseZustand';
import { Root } from '../../../../config/config';

import { MobileSubItem, MobileSubName, MobileFooter } from './style';

const MobileFooterPage = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { isAuth } = AuthZustand((state) => state);
    const { setAuthModal } = BaseZustand(
        (state) => state
    );
    const { sidebar } = useAuthZustand((state) => state);

    const [menuData, setMenuData] = useState([]);

    useEffect(() => {
        const data = sidebar.find((item) => item.name === 'For You');
        if (data && data.children) {
            const fdata = data.children.filter((item) => item.ismbfooter === true);
            setMenuData(fdata);
        }
    }, [sidebar]);

    const checkUrl = useCallback((item) => {
        const url = history.location.pathname;
        if (item.url === url) {
            return true;
        } else if (url === '/' && item.url === '/home') {
            return true;
        } else {
            return false;
        }
        // eslint-disable-next-line
    }, []);

    return (
        <MobileFooter>
            {menuData.map((item, key) => (
                <Fragment key={key}>
                    {item.name === 'Login' && !isAuth ? (
                        <>
                            {
                                <MobileSubItem
                                    selected={checkUrl(item)}
                                    onClick={() =>
                                        setAuthModal(
                                            {
                                                login: true,
                                                register: false
                                            },
                                            history
                                        )
                                    }
                                >
                                    <img
                                        src={`${Root.serverUrl}${item.image}`}
                                    />
                                    <MobileSubName>
                                        {t(item.name)}
                                    </MobileSubName>
                                </MobileSubItem>
                            }
                        </>
                    ) : (
                        <></>
                    )}
                    {item.name !== 'Deposit' && item.name !== 'Login' ? (
                        <>
                            {
                                <MobileSubItem
                                    selected={checkUrl(item)}
                                    onClick={() => history.push(item.url)}
                                >
                                    <img
                                        src={`${Root.serverUrl}${item.image}`}
                                    />
                                    <MobileSubName>
                                        {t(item.name)}
                                    </MobileSubName>
                                </MobileSubItem>
                            }
                        </>
                    ) : (
                        <></>
                    )}
                </Fragment>
            ))}
        </MobileFooter>
    );
};

export default MobileFooterPage;
