const origin = window.location.origin.replace('http://', '').replace('https://', '')
// const site = origin.includes('localhost') ? 'betatest123.com' : origin
const site = origin.includes('localhost') ? 'temp1.onlinecasinolucky.com' : origin
const domain = site.split('.').length > 2 ? `${site.split('.')[1]}.${site.split('.')[2]}` : site

export const Root =
    process.env.NODE_ENV === 'development'
        ? {
            adminWebsiteUrl: 'http://localhost:5000',
            serverUrl: 'http://localhost:3000',
            token: `${site}-token`,
            site,
            socket: null
        }
        : {
            adminWebsiteUrl: `https://admin.${domain}`,
            serverUrl: `https://server.${domain}`,
            token: `${site}-token`,
            site,
            socket: null
        };
