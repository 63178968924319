import { useEffect, useLayoutEffect, useState } from 'react';
import useBaseZustand from '../../../zustand/BaseZustand';

import { MainSidebarWrapper } from './style';

import ExtendedSidebar from './ExtendedSidebar';
import CollapseSidebar from './CollapseSidebar';
import { useHistory } from 'react-router-dom';

const MainSidebar = () => {
    const history = useHistory();
    const { isDesktopSidebar, setDesktopSidebar } = useBaseZustand((state) => state);
    const [currentItem, setCurrentItem] = useState('');

    const handleChooseItem = (url) => {
        setCurrentItem(url);
        history.push(url);
    };

    const handleChooseTournament = (url, data) => {
        setCurrentItem(url);
        history.push({ 
            pathname: url,
            state: data
           });
    };

    useEffect(() => {
        const flag = localStorage.getItem('MainSidebar')
        if (flag === 'true') {
            setDesktopSidebar(true)
        } else {
            setDesktopSidebar(false)
        }
    }, [])

    useEffect(() => {
        if (history.location.search) {
            setCurrentItem(
                history.location.pathname.concat(history.location.search)
            );
        } else {
            setCurrentItem(history.location.pathname);
        }
    }, [currentItem, history.location.pathname, history.location.search]);

    useLayoutEffect(() => { }, [history.location.pathname]);

    return (
        <MainSidebarWrapper
            open={isDesktopSidebar}
            display={String(history.location.pathname === '/game')}
        >
            {isDesktopSidebar ? (
                <ExtendedSidebar
                    currentItem={currentItem}
                    handleChooseItem={handleChooseItem}
                    handleChooseTournament={handleChooseTournament}
                />
            ) : (
                <CollapseSidebar
                    currentItem={currentItem}
                    handleChooseItem={handleChooseItem}
                    handleChooseTournament={handleChooseTournament}

                />
            )}
        </MainSidebarWrapper>
    );
};

export default MainSidebar;
