import axios from 'axios'
import { toast } from 'react-toastify'
import { ToastBox } from '../pages/ui-element/toast'
import { Root } from '../config/config'

const msgData = {
  last: '',
  timestamp: 0
}

class APIAbstract {

  constructor(apiEndpoint) {
    axios.interceptors.request.use(
      config => {
        config.baseURL = apiEndpoint
        config.headers = {
          authorization: `${this.getToken()}`,
          device: window.innerWidth
        }
        return config
      },
      error => Promise.reject(error)
    )
    axios.interceptors.response.use(
      response => response,
      error => {
        const { response } = error
        const timestamp = new Date().valueOf()
        if (response && (msgData.last !== response.data || (msgData.last === response.data && timestamp - msgData.timestamp > 1000))) {
          msgData.last = response.data
          msgData.timestamp = timestamp
          if (response && response.status === 401) {
            toast.error(<ToastBox letter="Session expired" />)
            setTimeout(() => {
              this.clearToken()
              window.location.reload()
            }, 100)
          }
          if(response.status === 405){
            window.location.assign('/blocked')
            return false
          }
          if (response.status === 400 || response.status === 401 || response.status === 403 || response.status === 500) {
            toast.error(<ToastBox letter={response.data.message || response.data} />)
            return false
          } else if (response.status === 404) {
            toast.error(<ToastBox letter="Not found endpoint" />)
            return false
          } else {
            return false
          }
        }
        return Promise.reject(error)
      }
    )
  }

  objectToQueryString(obj) {
    const str = []
    for (const p in obj) {
      /* eslint-disable-next-line */
      if (obj.hasOwnProperty(p)) {
        str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`)
      }
    }
    return str.join('&')
  }

  async get(url, data = {}) {
    return axios.get(`${url}?${this.objectToQueryString(data)}`)
  }

  async post(url, data = {}) {
    return axios.post(`${url}`, data)
  }

  async put(url, data = {}) {
    return axios.put(`${url}`, data)
  }

  async delete(url, data = {}) {
    return axios.delete(`${url}?${this.objectToQueryString(data)}`)
  }

  getToken() {
    const data = window.localStorage.getItem(Root.token)
    return data ? data : false
  }

  setToken(token) {
    window.localStorage.setItem(Root.token, token)
  }

  clearToken() {
    window.localStorage.removeItem(Root.token)
  }

}

export default APIAbstract
