import APIAbstract from './APIAbstract';
import { Root } from '../config/config';

class ConfigApi extends APIAbstract {
    getPlayerBaseData(data) {
        return this.post('/player/config/basedata', data);
    }
    getPlayerBaseInfo() {
        return this.post('/player/config/baseinfo');
    }
    getMyNotification(data = {}) {
        return this.post('/player/config/getMyNotification', data);
    }
    readAllNotification(data = {}) {
        return this.post('/player/config/readAllNotification', data);
    }
    getMultilang() {
        // return this.post('/api/config/getMultilang');
        return null
    }

}

const ConfigClass = new ConfigApi(`${Root.serverUrl}`);

export default ConfigClass;
