import { useEffect, useState } from 'react';
import * as S from './style';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { shuffleArray } from './helpers';
import { GameItemData } from '../../types';
import GameZustand from '../../../../zustand/GameZustand';

const GameWidget = () => {
    const { selectGame, getHomePageGameData } = GameZustand((state) => state);
    const [mostPlayedGames, setMostPlayedGames] = useState([]);
    const [providerList, setProviderList] = useState([]);

    const getMostPlayedGames = async () => {
        const gameData = await getHomePageGameData();
        if (gameData) {
            setMostPlayedGames(gameData.topGames);
            setProviderList(gameData.providers);
        }
    };

    const startGame = (gameItem: GameItemData) => {
        const findProvider = providerList.find(
            (item) => item.value === gameItem.provider
        );
        selectGame(gameItem, findProvider);
    };

    useEffect(() => {
        getMostPlayedGames();
    }, []);

    return (
        <S.MostPlayedGamesPopup>
            <S.MostPlayedGamesPopupHeader
                expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
            >
                <S.MostPlayedGamesTitle>
                    Most played games
                </S.MostPlayedGamesTitle>
            </S.MostPlayedGamesPopupHeader>
            <S.MostPlayedGamesPopupContent>
                {shuffleArray(mostPlayedGames).map((item) => (
                    <S.MostPlayedGamesItem
                        key={item.id}
                        onClick={() => startGame(item)}
                    >
                        <S.MostPlayedGamesImageContainer>
                            <img src={item.image} alt="" />
                        </S.MostPlayedGamesImageContainer>
                        <S.MostPlayedGamesGameTextWrapper>
                            <S.MostPlayedGamesGameName>
                                {item.name}
                            </S.MostPlayedGamesGameName>
                            <S.MostPlayedGamesProviderName>
                                {
                                    providerList.find(
                                        (it) => it.value === item.provider
                                    )?.label
                                }
                            </S.MostPlayedGamesProviderName>
                        </S.MostPlayedGamesGameTextWrapper>
                    </S.MostPlayedGamesItem>
                ))}
            </S.MostPlayedGamesPopupContent>
        </S.MostPlayedGamesPopup>
    );
};

export default GameWidget;
