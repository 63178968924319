import create from 'zustand'
import { toast } from 'react-toastify'
import ProfileApi from '../api/ProfileApi'
import { ToastBox } from '../pages/ui-element/toast'

const useStore = create(() => ({
    playerProfileUpdate: async (data) => {
        const flag = await ProfileApi.playerProfileUpdate(data)
        if (flag) {
            toast.success(<ToastBox letter='Success' />)
        }
    },
    changepassword: async (data) => {
        const flag = await ProfileApi.changepassword(data)
        if (flag) {
            toast.success(<ToastBox letter='Success' />)
        }
    },
    getSessionHistory: async (data) => {
        const rdata = await ProfileApi.getSessionHistory(data)
        if (rdata) {
            return rdata.data.data
        } else {
            return []
        }
    },
    updateLanguage: (data) => {
        ProfileApi.updateLanguage(data)
    }
}))

export default useStore
