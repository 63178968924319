import { createRoot } from 'react-dom/client';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { ToastContainer } from 'react-toastify';

import App from './AppRouter';
import { LoadingProvider } from './layout/context/loading';
import AuthProvider from './layout/context/auth';
import reportWebVitals from './reportWebVitals';
import Contextloading from './pages/ui-element/loading';
import { ThemeProvider } from './theme/ThemeProvider';
import GlobalStyles from './components/GlobalStyles';
import { testWebpSupport } from './helpers';

import 'swiper/css/bundle';
import 'react-responsive-modal/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'react-phone-input-2/lib/style.css';
import './assets/index.scss';
import './config/i18n';

const container = document.getElementById('root');

const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
    // <React.StrictMode>
    // </React.StrictMode>
    <LoadingProvider>
        <AuthProvider>
            <ThemeProvider>
                <StyledEngineProvider injectFirst>
                    <ToastContainer />
                    <Contextloading />
                    <App />
                    <GlobalStyles />
                </StyledEngineProvider>
            </ThemeProvider>
        </AuthProvider>
    </LoadingProvider>
);

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js');
}

reportWebVitals();
testWebpSupport();
