import { useState } from 'react';

import AuthZustand from '../../../../zustand/AuthZustand';

import { Root } from '../../../../config/config';

import { User, Balance, BalanceImg } from './style';

const Header = () => {
    const { user, balance, currencies } = AuthZustand((state) => state);

    const [balanceDropTag, setBalanceDropTag] = useState(null);
    const balanceOpen = Boolean(balanceDropTag);
    const balanceId = balanceOpen ? 'simple-popover' : undefined;

    const getCurrencyImg = (currency) => {
        let flag;
        if (currency.includes('-')) {
            flag = currencies.find(
                (item) => `${item.currency}-${item.network}` === currency
            );
        } else {
            flag = currencies.find((item) => item.currency === currency);
        }

        if (flag && flag.type === 'crypto') {
            return `${Root.serverUrl}/image/site/coins/${currency
                .split('-')[0]
                .toLocaleLowerCase()}@2x.png`;
        } else {
            return `https://s2.coinmarketcap.com/static/cloud/img/fiat-flags/${currency}.svg`;
        }
    };

    return (
        <>
            <User>
                <Balance
                    aria-describedby={balanceId}
                    onClick={(e) => setBalanceDropTag(e.currentTarget)}
                >
                    <p>{balance}</p>
                    &nbsp;
                    {user.currency.includes('-') ? (
                        <p>{`${user.currency.split('-')[0]}`}</p>
                    ) : (
                        <p>{user.currency}</p>
                    )}
                    <BalanceImg src={getCurrencyImg(user.currency)} />
                </Balance>
            </User>
        </>
    );
};

export default Header;
