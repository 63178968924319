export const defaultIdValue = '7e3d75d6-9b75-4b23-8829-f42d317945f4'

export const defaultProvider = {
    value: '0',
    label: 'All'
};
export const defaultItem = {
    value: '',
    label: 'All'
};

export const genderData = [
    { value: '', label: 'Please Select' },
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' }
];

export const BetStatusList = [
    { value: '', label: 'All' },
    { value: 'win', label: 'WIN' },
    { value: 'bet', label: 'BET' }
];

export const PayTypes = [
    { value: '', label: 'All' },
    { value: 'deposit', label: 'Deposit' },
    { value: 'withdraw', label: 'Withdrawal' }
];

export const PayStatus = [
    { value: '', label: 'All' },
    { value: 'success', label: 'Success' },
    { value: 'pending', label: 'Pending' },
    { value: 'failure', label: 'Failure' },
    { value: 'waiting_confirm', label: 'Waiting' }
];
