import create from 'zustand';

const useStore = create<any>((set) => ({
    isDesktopSidebar: true,
    sidebar: false,
    gamemode: false,
    authModal: { login: false },

    setDesktopSidebar: (flag: boolean) => {
        localStorage.setItem('MainSidebar', String(flag))
        set({ isDesktopSidebar: flag });
    },
    setAuthModal: async (authModalData: any) => {
        set({ authModal: authModalData });
    },
    setSidebar: async (flag: boolean) => {
        set({ sidebar: flag });
    },
    setGameModal: async (flag) => {
        set({ gamemode: flag });
    }
}));

export default useStore;
